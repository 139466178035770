import { EmailMessage, getEmptyEmailMessage } from '@/views/Programs/Communications/model/EmailMessage'
import { EmailSender, getEmptyEmailSender } from '@/views/Programs/Communications/model/EmailSender'
import { Attachment, getEmptyAttachment } from '@/GeneratedTypes/Communication/Attachment'

export interface EmailBase {
  message: EmailMessage
  sender: EmailSender
  attachment1: Attachment
  attachment2: Attachment
}

export function getEmptyEmailBase(): EmailBase {
  return {
    message: getEmptyEmailMessage(),
    sender: getEmptyEmailSender(),
    attachment1: getEmptyAttachment(),
    attachment2: getEmptyAttachment(),
  }
}
