export interface Attachment {
  data: string | null
  name: string | null
  type: string | null
}

export function getEmptyAttachment(): Attachment {
  return {
    data: '',
    name: '',
    type: '',
  }
}
