



import { defineComponent, PropType, computed } from '@vue/composition-api'

import FileInput from '@/elements/FileInput.vue'
import { Attachment } from '@/GeneratedTypes/Communication/Attachment'
import { getEmptyAttachment } from '@/lib/support/models/GeneratedTypes/Communication/Attachment'

export default defineComponent({
  name: 'AttachmentInput',
  props: {
    value: { type: Object as PropType<Attachment>, required: true, default: getEmptyAttachment() },
  },
  components: { FileInput },
  setup(props, { emit }) {
    const name = computed({
      get() {
        return props.value.name ?? ''
      },
      set(v: string) {
        const a = composeAttachment()
        a.name = v
        emit('input', a)
      },
    })
    const type = computed({
      get() {
        return props.value.type ?? ''
      },
      set(v: string) {
        const a = composeAttachment()
        a.type = v
        emit('input', a)
      },
    })
    const data = computed({
      get() {
        return props.value.data ?? ''
      },
      set(v: string) {
        const a = composeAttachment()
        a.data = v
      },
    })
    const composeAttachment = () => {
      const a = getEmptyAttachment()
      a.data = data.value ?? ''
      a.name = name.value ?? ''
      a.type = type.value ?? ''
      return a
    }
    function handleAttachmentChange(file: File) {
      if (file) {
        // Capture file name and file type in attachmentObject
        const a = composeAttachment()
        a.name = file.name ?? ''
        a.type = file.type ?? ''
        emit('input', a)
        // Encode attachment as Base64
        const reader = new FileReader()
        reader.onload = loadFile
        reader.readAsDataURL(file)
      } else {
        emit('input', getEmptyAttachment())
      }
    }
    function loadFile(ev: ProgressEvent<FileReader>) {
      const a = composeAttachment()
      if (!ev || !ev.target || !ev.target.result) {
        a.data = ''
        a.type = ''
        a.name = ''
        emit('input', a)
        return
      }
      // Capture Base64 encoding and remove extranious data before the comma
      const result = ev.target.result.toString().split(',')
      if (result.length >= 2) {
        a.data = result[1] ?? ''
        emit('input', a)
      } else {
        throw 'Error encoding email attachment'
      }
    }
    return { handleAttachmentChange }
  },
})
