







































































import { defineComponent, ref, watch, computed, onUnmounted, onMounted } from '@vue/composition-api'
import { cloneDeep, debounce } from 'lodash'

import EmailComposer from '@/views/Programs/Communications/vue/EmailComposer.vue'
import EmailPreviewer from '@/views/Programs/Communications/vue/EmailPreviewer.vue'
import Loading from '@/elements/Loading.vue'
import MessageConfirmationModal from '@/views/Programs/Communications/vue/MessageConfirmationModal.vue'
import MessageFilterModal from '@/views/Programs/Communications/vue/Filters/MessageFilterModal.vue'
import CommunicationWarning from '@/views/Programs/Communications/vue/CommunicationWarning.vue'

import { EmailCompose, getEmptyEmailCompose } from '@/views/Programs/Communications/model/EmailCompose'
import { PreviewMessage } from '@/models/Communication/PreviewMessage'
import { getEmptyPreviewMessage } from '@/lib/support/models/Communication/PreviewMessage'
import { EmailMessageImpl, getEmptyEmail } from '@/views/Programs/Communications/ext/EmailMessageImpl'
import { MessageFilters, convertToMessageFilters } from '@/views/Programs/Communications/model/MessageFilters'
import store from '@/store'
import { isEmailValid } from '@/lib/support/utilities/email/emailUtil'
import { messageLogic } from '@/views/Programs/Communications/ext/MessageLogic'
import router from '@/router/index'
import { mixin as clickaway } from 'vue-clickaway'
import DatePicker from '@/components/ModalDateTimePicker.vue'
import { getEmptyAttachment } from '@/GeneratedTypes/Communication/Attachment'
export default defineComponent({
  name: 'Email',
  props: {},
  components: {
    EmailComposer,
    EmailPreviewer,
    Loading,
    MessageConfirmationModal,
    MessageFilterModal,
    CommunicationWarning,
    DatePicker,
  },
  mixins: [clickaway],
  setup(props, ctx) {
    const messageID = computed(() => router.currentRoute.params.messageId)
    const logic = messageLogic()
    const compose = ref<EmailCompose>(getEmptyEmailCompose())
    const emailComposer = ref<any | null>(null)
    const hasCredits = computed(() => {
      return (
        logic.providerInfo.value.emailMaxCredits < 0 ||
        logic.providerInfo.value.emailCredits < logic.providerInfo.value.emailMaxCredits
      )
    })

    const isAllowed = computed(() => {
      return logic.providerInfo.value.isEmailAllowed
    })

    const apiPreview = computed(
      (): PreviewMessage => {
        //remove any invalid emails and convert to string
        const toDelimited = compose.value.recipients.toDelimited.filter((e) => isEmailValid(e)).join(', ')
        return {
          ...getEmptyPreviewMessage(),
          ...compose.value.message,
          ...compose.value.sender,
          ...logic.filters.value,
          selectedGroups: compose.value.recipients.selectedGroups,
          toDelimited,
          upwardLeagueID: logic.upwardLeagueID.value ?? '',
          accountNumber: logic.accountNumber.value,
          communicationType: 'email',
        }
      }
    )

    const apiMessage = computed(
      (): EmailMessageImpl => {
        const m = {
          ...getEmptyEmail(),
          ...compose.value.message,
          ...compose.value.sender,
          to: logic.allRecipients.value,
          upwardLeagueID: logic.upwardLeagueID.value ?? '',
          accountNumber: logic.accountNumber.value,
          sendCommunicationOn: logic.sendCommunicationOn.value,
          minutesToAdd: logic.minutesToAdd.value,
          recipientCriteria: {
            ...logic.filters.value,
            upwardLeagueID: logic.upwardLeagueID.value ?? '',
            accountNumber: logic.accountNumber.value,
            selectedGroups: compose.value.recipients.selectedGroups,
            individualContactInfo: compose.value.recipients.toDelimited.filter((e) => isEmailValid(e)),
          },
        }
        if (compose.value.attachment1.data) {
          m?.attachments?.push(cloneDeep(compose.value.attachment1))
        }
        if (compose.value.attachment2.data) {
          m?.attachments?.push(cloneDeep(compose.value.attachment2))
        }
        return m
      }
    )

    const loadRecipients = debounce(async () => {
      await logic.fetchRecipients(apiPreview.value)
    }, 500)

    async function sendEmail() {
      const isValid = await emailComposer.value.triggerValidation()
      if (isValid) {
        logic.send(apiMessage.value, 'sendemail')
      }
    }
    async function scheduleSend() {
      logic.showScheduleSendModal.value = (await emailComposer.value.triggerValidation()) as boolean
    }

    async function loadEmailCompose() {
      const emailCompose = getEmptyEmailCompose()
      if (messageID.value) {
        const message = await logic.retrieveMessage(parseInt(messageID.value), 'email')
        emailCompose.message.emailSubject = message?.emailSubject ?? ''
        emailCompose.message.messageBody = message?.messageBody ?? ''
        emailCompose.sender.displayName = message?.emailDisplayName ?? ''
        emailCompose.sender.replyTo = message?.emailReplyTo ?? ''
        emailCompose.recipients.selectedGroups = message?.recipientCriteria?.selectedGroups ?? []
        emailCompose.recipients.toDelimited = message?.recipientCriteria?.individualContactInfo ?? []
        const attachments = message?.emailAttachments ?? []
        emailCompose.attachment1 = attachments[0] ?? getEmptyAttachment()
        emailCompose.attachment2 = attachments[1] ?? getEmptyAttachment()
        logic.filters.value = convertToMessageFilters(message?.recipientCriteria)
      }
      compose.value = emailCompose
    }
    function loadTeams() {
      return new Promise<void>((resolve) => {
        ctx.root.$nextTick(async () => {
          try {
            logic.allTeams.value = await store.dispatch.teams.fetchTeams({
              upwId: logic.upwardLeagueID.value ?? '',
            })
          } finally {
            resolve()
          }
        })
      })
    }
    function filterChange(msgFilters: MessageFilters) {
      logic.filters.value = msgFilters
      loadRecipients()
    }
    function removeAttachment(n: number) {
      if (n === 1) compose.value.attachment1 = getEmptyAttachment()
      else compose.value.attachment2 = getEmptyAttachment()
    }

    watch(
      () => apiPreview.value.toDelimited,
      async () => {
        await loadRecipients()
      }
    )

    watch(
      () => apiPreview.value.selectedGroups,
      async (newValue, oldValue) => {
        if ([...newValue].sort().join() != [...oldValue].sort().join()) {
          await loadRecipients()
        }
      }
    )

    onMounted(async () => {
      await loadEmailCompose()
      await loadTeams()
    })
    onUnmounted(() => store.commit.communications.setPreviewMessage({ item: getEmptyPreviewMessage() }))

    return {
      compose,
      sendEmail,
      scheduleSend,
      emailComposer,
      filterChange,
      hasCredits,
      isAllowed,
      removeAttachment,
      ...logic,
    }
  },
})
