export interface EmailMessage {
  emailSubject: string
  messageBody: string
}

export function getEmptyEmailMessage(): EmailMessage {
  return {
    emailSubject: '',
    messageBody: '',
  }
}
