export interface EmailSender {
  displayName: string
  replyTo: string
}

export function getEmptyEmailSender(): EmailSender {
  return {
    displayName: '',
    replyTo: '',
  }
}
