












































import { defineComponent, PropType, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import RecipientListPreview from '@/components/Communication/RecipientListPreview.vue'
import InputLabel from '@/elements/InputLabel.vue'
import Loading from '@/elements/Loading.vue'
import ToClipboardBtn from '@/elements/ToClipboardBtn.vue'

import { MessageChannelTypes } from '@/components/Communication/MessageChannelData'
import { EmailCompose } from '@/views/Programs/Communications/model/EmailCompose'

import { mergeAllRecipients } from '@/views/Programs/Communications/ext/allRecipients'

import store from '@/store'

export default defineComponent({
  name: 'EmailPreviewer',
  props: {
    compose: { type: Object as PropType<EmailCompose>, require: true },
    loadingRecipients: { type: Boolean, require: false, default: false },
  },
  data() {
    return {
      MessageChannelTypes,
    }
  },
  components: { RecipientListPreview, InputLabel, Loading, ToClipboardBtn },
  setup(props, { emit }) {
    const count = computed(() => recipients.value.length)

    const previewMessage = computed(() => store.getters.communications.messagePreview)

    const recipients = computed(() => {
      return mergeAllRecipients(cloneDeep(previewMessage.value))
    })

    function filterClick() {
      emit('filterClick')
    }

    function removeAttachment(n: number) {
      emit('removeAttachment', n)
    }
    return { count, recipients, filterClick, removeAttachment }
  },
})
