



























import { defineComponent, ref, PropType, computed } from '@vue/composition-api'
import { cloneDeep } from 'lodash'

import VeeValidateForm from '@/elements/VeeValidateForm.vue'
import EmailSenderInput from '@/views/Programs/Communications/vue/EmailSenderInput.vue'
import EmailRecipientInput from '@/views/Programs/Communications/vue/EmailRecipientInput.vue'
import EmailMessageInput from '@/views/Programs/Communications/vue/EmailMessageInput.vue'
import AttachmentInput from '@/views/Programs/Communications/vue/AttachmentInput.vue'
import InputlessStringValidator from '@/elements/InputlessStringValidator.vue'
import InputLabel from '@/elements/InputLabel.vue'

import { EmailSender } from '@/views/Programs/Communications/model/EmailSender'
import { Recipients } from '@/views/Programs/Communications/model/Recipients'
import { EmailMessage } from '@/views/Programs/Communications/model/EmailMessage'
import { Attachment } from '@/GeneratedTypes/Communication/Attachment'
import { getEmptyAttachment } from '@/lib/support/models/GeneratedTypes/Communication/Attachment'
import { EmailCompose, getEmptyEmailCompose } from '@/views/Programs/Communications/model/EmailCompose'

import { mergeAllRecipients } from '@/views/Programs/Communications/ext/allRecipients'

import store from '@/store'

export default defineComponent({
  name: 'EmailComposer',
  props: {
    value: { type: Object as PropType<EmailCompose>, required: false, default: () => getEmptyEmailCompose() },
  },
  components: {
    VeeValidateForm,
    EmailSenderInput,
    EmailRecipientInput,
    AttachmentInput,
    EmailMessageInput,
    InputlessStringValidator,
    InputLabel,
  },
  setup(props, { emit }) {
    const form = ref<any | null>(null)
    function setAttachment(att: Attachment, which: number) {
      which == 1 ? (attachment1.value = att) : (attachment2.value = att)
    }

    function composeEmailCompose(): EmailCompose {
      return {
        recipients: recipients.value,
        message: message.value,
        sender: sender.value,
        attachment1: attachment1.value,
        attachment2: attachment2.value,
      }
    }

    const sender = computed({
      get() {
        return props.value.sender
      },
      set(v: EmailSender) {
        const data = composeEmailCompose()
        data.sender = cloneDeep(v)
        emit('input', data)
      },
    })

    const message = computed({
      get() {
        return props.value.message
      },
      set(v: EmailMessage) {
        const data = composeEmailCompose()
        data.message = cloneDeep(v)
        emit('input', data)
      },
    })

    const recipients = computed({
      get() {
        return props.value.recipients
      },
      set(v: Recipients) {
        const data = composeEmailCompose()
        data.recipients = cloneDeep(v)
        emit('input', data)
      },
    })

    const attachment1 = computed({
      get() {
        return props.value.attachment1
      },
      set(v: Attachment) {
        const data = composeEmailCompose()
        data.attachment1 = cloneDeep(v ?? getEmptyAttachment())
        emit('input', data)
      },
    })
    const attachment2 = computed({
      get() {
        return props.value.attachment2
      },
      set(v: Attachment) {
        const data = composeEmailCompose()
        data.attachment2 = cloneDeep(v ?? getEmptyAttachment())
        emit('input', data)
      },
    })

    const previewMessage = computed(() => store.getters.communications.messagePreview)

    const fetchedRecipients = computed(() => {
      return mergeAllRecipients(cloneDeep(previewMessage.value)).join()
    })

    const showAttachments = ref(true)
    function gospelVideoChange(videoId: string) {
      showAttachments.value = videoId == 'None'
      if (!showAttachments.value) {
        clearAttachments()
      }
    }

    function clearAttachments() {
      attachment1.value = getEmptyAttachment()
      attachment2.value = getEmptyAttachment()
    }

    async function triggerValidation() {
      return await form.value.reportValidity()
    }

    return {
      form,
      sender,
      recipients,
      message,
      attachment1,
      attachment2,
      setAttachment,
      fetchedRecipients,
      triggerValidation,
      showAttachments,
      gospelVideoChange,
    }
  },
})
