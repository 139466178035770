






















import { defineComponent, PropType, computed, ref, watch } from '@vue/composition-api'
import { cloneDeep } from 'lodash'
import TextInput from '@/elements/TextInput.vue'
import { EmailSender, getEmptyEmailSender } from '@/views/Programs/Communications/model/EmailSender'

import communicationsClient from '@/clients/communicationsClient'

import store from '@/store'

export default defineComponent({
  name: 'EmailSenderInput',
  props: {
    value: { type: Object as PropType<EmailSender>, required: true },
  },
  components: { TextInput },

  setup(props, { emit, root }) {
    const local = ref<EmailSender>(getEmptyEmailSender())

    const currentItem = computed(() => {
      return store.getters.communications.currentItem
    })

    const accountId = computed(() => {
      return currentItem.value.emailSmsAccountID
    })

    async function fetchSettings() {
      return await communicationsClient.retrieveLeagueSettings(root.$route.params.id, accountId.value!)
    }
    watch(
      () => accountId.value,
      async () => {
        if (!accountId.value) return
        const settings = await fetchSettings()
        if (settings) {
          displayName.value = settings.emailDisplayName ?? ''
          replyTo.value = settings.emailReplyTo ?? ''
        }
      },
      { immediate: true }
    )

    const displayName = computed({
      get() {
        return props.value.displayName
      },
      set(v: string) {
        local.value.displayName = v
        emit('input', cloneDeep(local.value))
      },
    })

    const replyTo = computed({
      get() {
        return props.value.replyTo
      },
      set(v: string) {
        local.value.replyTo = v
        emit('input', cloneDeep(local.value))
      },
    })

    return { displayName, replyTo, currentItem }
  },
})
