






























import { defineComponent, ref, computed } from '@vue/composition-api'
import InputLabel from '@/elements/InputLabel.vue'

export default defineComponent({
  name: 'FileInput',
  components: {
    InputLabel,
  },
  props: {
    label: { type: String, default: '', required: false },
    required: { type: Boolean, default: false, required: false },
    placeholder: { type: String, default: '', required: false },
    maxSize: { type: Number, default: 0, required: false },
  },
  setup(props, ctx) {
    const fileName = ref('')
    const fileSize = ref(0)

    function handleChange(ev: Event) {
      if (!ev || !ev.target) {
        return
      }

      const fileInput = ev.target as HTMLInputElement

      if (!fileInput || !fileInput.files) {
        return
      }

      const file = fileInput.files[0]

      if (!file) {
        fileName.value = ''
        fileSize.value = 0
      } else {
        fileName.value = file.name
        fileSize.value = file.size
      }
      console.log(fileSize.value)
      checkSize()

      ctx.emit('change', file)
    }

    function checkSize() {
      if (props.maxSize === 0) return
      if (fileSize.value > props.maxSize) {
        remove()
        throw `The file exceeds the maximum size of ${maxSizeFormatted.value}.`
      }
    }

    function remove() {
      ;(ctx.refs.fileSelector as HTMLInputElement).value = ''
      fileName.value = ''
      fileSize.value = 0
      ctx.emit('change', null)
    }

    const fileSizeFormatted = computed(() => getSizeFormatted(fileSize.value))

    const maxSizeFormatted = computed(() => getSizeFormatted(props.maxSize))

    function getSizeFormatted(size: number) {
      if (!size) {
        return ''
      }
      if (size / 1000000 >= 1) {
        return `${Math.round(size / 1000000)}MB`
      }
      if (size / 1000 >= 1) {
        return `${Math.round(size / 1000)}KB`
      }
      return `${size}B`
    }

    return {
      fileName,
      fileSize,
      fileSizeFormatted,
      handleChange,
      remove,
    }
  },
})
