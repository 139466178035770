import { EmailBase, getEmptyEmailBase } from '@/views/Programs/Communications/model/EmailBase'
import { Recipients, getEmptyRecipients } from '@/views/Programs/Communications/model/Recipients'

export interface EmailCompose extends EmailBase {
  recipients: Recipients
}

export function getEmptyEmailCompose(): EmailCompose {
  return {
    ...getEmptyEmailBase(),
    recipients: getEmptyRecipients(),
  }
}
