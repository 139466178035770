




















import { defineComponent, PropType, computed, ref } from '@vue/composition-api'

import TextInput from '@/elements/TextInput.vue'
import HtmlEditor from '@/elements/HtmlEditor2.vue'

import { EmailMessage, getEmptyEmailMessage } from '@/views/Programs/Communications/model/EmailMessage'
import GospelVideoSelector from '@/components/Communication/GospelViedoSelector/GospelVideoSelector.vue'

import { GospelVideoOptions } from '@/components/Communication/GospelViedoSelector/GospelVideoSelectorData'

export default defineComponent({
  name: 'EmailMessageInput',
  props: {
    value: { type: Object as PropType<EmailMessage>, required: false, default: () => getEmptyEmailMessage() },
  },
  data() {
    return { GospelVideoOptions }
  },
  components: { TextInput, GospelVideoSelector, HtmlEditor },
  setup(props, { emit, root }) {
    const showVideo = ref(false)
    const defaultVideoId = computed(() => {
      const noneId = GospelVideoOptions.get('None')?.id
      return noneId ?? ''
    })

    const subject = computed({
      get() {
        return props.value.emailSubject
      },
      set(val: string) {
        const emailMessage = composeEmailMessage()
        emailMessage.emailSubject = val
        emit('input', emailMessage)
      },
    })

    const vidId = ref(defaultVideoId.value)
    const videoId = computed({
      get() {
        return vidId.value
      },
      set(val: string) {
        vidId.value = val
        emit('gospelVideoChange', val)
        setGospelVideo()
      },
    })

    const body = computed({
      get() {
        return props.value.messageBody
      },
      set(val: string) {
        const emailMessage = composeEmailMessage()
        emailMessage.messageBody = val
        emit('input', emailMessage)
      },
    })

    function composeEmailMessage() {
      return {
        emailSubject: subject.value,
        messageBody: body.value,
      }
    }

    function toggle() {
      showVideo.value = !showVideo.value
    }

    function setGospelVideo() {
      const videoData = GospelVideoOptions.get(videoId.value)
      if (videoData) {
        subject.value = videoData.title == 'None' ? '' : videoData.title
        root.$nextTick(
          () => (body.value = `${videoData.emailText}<br/><a href="${videoData.url}">${videoData.url}</a>`)
        )
      }
    }

    return { subject, body, videoId, showVideo, toggle }
  },
})
